<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}预约单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="车牌" prop="vehicle.id">
        <select-maintain
          v-model="form.data.vehicle"
          :ajax="{
            action: 'GET /enocloud/common/vehicle',
            params: (params, value) => (params.payload = { quickSearch: value })
          }"
          :props="{ label: 'plateNo', value: '' }"
          value-key="id"
          remote
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          @change="form.vehicle.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="车主" prop="customer.id">
        <select-maintain
          v-model="form.data.customer"
          :ajax="{
            action: 'GET /enocloud/common/warehouse',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          @change="form.customer.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="预约门店" prop="branch.id">
        <select-maintain
          v-model="form.data.branch"
          :ajax="{
            action: 'GET /enocloud/common/branch',
            params: (params, value) => (params.payload = { quickSearch: value })
          }"
          :props="{ label: 'shortName', value: '' }"
          value-key="id"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="预约日期" prop="reservationDate">
        <en-date-picker
          v-model="form.data.reservationDate"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="预约时间" prop="reservationTime">
        <select-maintain
          v-model="form.data.reservationTime"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['NOONTYPE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="服务顾问">
        <select-maintain
          v-model="form.data.advisor"
          :ajax="{
            action: 'GET /enocloud/common/advisorteam/advisor',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'user.name', value: 'user' }"
          value-key="id"
          remote
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="送修人">
        <en-input
          v-model="form.data.senderName"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C' || form.data.service?.id"
        ></en-input>
      </en-form-item>
      <en-form-item label="电话">
        <en-input
          v-model="form.data.senderCellphone"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C' || form.data.service?.id"
        ></en-input>
      </en-form-item>
      <en-form-item label="本次里程">
        <en-input v-model="form.data.currentMileage" :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"></en-input>
      </en-form-item>
      <en-form-item label="是否保养">
        <select-maintain
          v-model="form.data.maintenanceFlag"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['FLAG'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="下保里程">
        <en-input
          v-model="form.data.currentMileage"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C' || form.data.service?.id"
        ></en-input>
      </en-form-item>
      <en-form-item label="下保时间">
        <en-date-picker
          v-model="form.data.nextMaintenanceDate"
          :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :disabled="form.data.status?.code === 'F' || form.data.status?.code === 'C'" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceReservationDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'vehicle.id': [{ required: true, message: '请选择车牌' }],
          'customer.id': [{ required: true, message: '请选择车主' }],
          'branch.id': [{ required: true, message: '请选择预约门店' }],
          reservationDate: [{ required: true, message: '请选择预约日期' }],
          reservationTime: [{ required: true, message: '请选择预约时间' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/reservation/:serviceReservationId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/service/reservation',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/service/reservation',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          }
        },
        children: {
          vehicle: {
            change(value: EnocloudServiceDefinitions['VehicleDto']) {
              this.form.data.plateNo = value.plateNo
              this.form.data.vehicleSpec = this.form.data.vehicleSpec ?? []
              this.form.data.customer = this.form.data.vehicle?.owner
              this.form.data.customerName = this.form.data.vehicle?.owner?.name ?? ''
              this.form.data.customerCellphone = this.form.data.vehicle?.owner?.cellphone ?? ''
            }
          },
          customer: {
            change(value: EnocloudServiceDefinitions['CustomerDto']) {
              this.form.data.customerName = value.name ?? ''
              this.form.data.customerCellphone = value.cellphone ?? ''
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
